@import "src/style/variables";

.actionmenu-component {
    position: relative;
    width: 24px;
    height: 24px;

    > span,
    > i {
        display: inline-block;
        vertical-align: middle;
    }

    // > span {
    //     margin-right: $gutterXSmall;
    // }

    i {
        color: color("p-dark-grey-1");
        fill: color("p-mid-grey-2");
        stroke-width: 15 !important;
        padding: 0 4px 0 4px;
        -webkit-transition: background-color $fastTransition;
        -moz-transition: background-color $fastTransition;
        -o-transition: background-color $fastTransition;
        transition: background-color $fastTransition;
    }

    .Visage2Icon {
        position: relative;

        g {
            path {
                &:first-child {
                    opacity: 0;
                }
            }
        }
    }

    &__toggler {
        display: inline-block;
        line-height: 0;
        outline: 0;
    }

    &__menu {
        min-width: 9em;
    }

    &.hover-effect {
        i {
            &:hover {
                background-color: color("p-light-grey-1");
                border-radius: $borderRadiusSharper;
            }
        }
    }
}

.StandardActionMenu {
    max-width: 24px;
    max-height: 24px;
}