@import "src/style/variables.scss";

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}  
}

.SpintrSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: color(p-white);
    flex-grow: 1;
    border-radius: $borderRadiusRound;
    border: 1px solid color(p-light-grey-6);
    height: 46px;
    max-width: 440px;

    .Visage2Icon {
        margin-left: $gutterMedium;
        margin-top: -1px;

        svg {
            * {
                transition: all 0.5s;
            }
        }
    }

    .ms-SearchBox {
        flex-grow: 1;
        border: 0;
        margin-left: $gutterSmaller;
        background-color: transparent;

        .ms-SearchBox-iconContainer {
            display: none;
        }
    
        input{
            @include placeholder {
                color: color(p-mid-grey-5);
            }
        }

        .ms-SearchBox-clearButton {
            margin-right: $gutterSmaller;
        }
    }

    &.hasValue {
        .Visage2Icon {
            svg {
                * {
                    stroke: color(p-blue-1);
                }
            }
        }
    }

    &.SpintrSearch-small {
        height: 32px;
        max-width: 300px;
        border-radius: $borderRadius;

        .Visage2Icon {
            margin-left: $gutterSmaller;
        }

        .ms-SearchBox {
            .ms-SearchBox-clearButton {
                margin-right: 0px;
            }
        }
    }
}