@import "src/style/variables";

.Notifications {
    .Notifications-button {
        height: 40px;
        width: 40px;
        border-radius: 40px;
        border: 1px solid color(p-light-grey-5);
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        position: relative;

        .button-unread-dot {
            background-color: color(p-red-1);
            width: 6px;
            height: 6px;
            border-radius: 6px;
            position: absolute;
            top: 11px;
            right: 12px;
        }
    }

    &.visible, &:hover {
        .Notifications-button {
            border: 1px solid color(p-border-color);
            background-color: color(p-border-color);
        }
    }
}

.Phoenix-callout {
    width: 375px;
    border-radius: $borderRadiusRound2;

    &.wide {
        width: 675px;
    }

    .ms-Callout-main {
        border-radius: $borderRadiusRound2;
    }

    .Phoenix-callout-header {
        padding: $gutterSmall $gutterMedium;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid color(p-border-color);

        .mark-all-as-unread {
            .label-component {
                text-decoration: underline;
            }

            &:hover {
                opacity: 0.75;
            }
        }
    }

    .Notification-list {
        max-height: 50vh;
        overflow-y: scroll;

        .NotificationItem {
            width: 100%;
            padding: $gutterSmall $gutterMedium;
            border-bottom: 1px solid color(p-light-grey-1);

            .label-component {
                text-decoration: none !important;
            }

            .top-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: $gutterSmaller;

                .label-component {
                    flex-grow: 1;
                }

                .unread-circle {
                    background-color: color(p-red-1);
                    width: 10px;
                    height: 10px; 
                    border-radius: 10px;
                }
            }

            &.isUnread {
                background-color: color(p-light-grey-1);
            }

            &:last-child {
                border-bottom: 0;
            }

            &:hover {
                background-color: color(p-light-grey-1);
            }
        }
    }

    .load-more {
        padding: $gutterMedium;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
}