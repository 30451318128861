@import "src/style/variables";

.SpintrUserMenu > .menuWrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border-radius: 28px;

    .ms-Persona-primaryText {
        font-weight: 550;
    }

    #submenu-actionmenu {
        height: 16px;

        .Visage2Icon {
            position: relative;
            top: 0px;
        }
    }
}

.SpintrUserMenu {
    &:hover {
        opacity: 0.75;
    }
}