@import "src/style/variables";

.LabelBox {
    display: inline-block;
    background-color: color(p-white);
    border-radius: $borderRadiusRounder;
    padding: $gutterXSmall $gutterSmaller;
    border: 1px solid color(p-white);

    &.prio-3 {
        border: 1px solid color(p-yellow-2);

        .label-component {
            color: color(p-yellow-3);
        }
    }

    &.prio-2 {
        border: 1px solid color(p-pink-1);

        .label-component {
            color: color(p-red-1);
        }
    }

    &.prio-1 {
        border: 1px solid color(p-mid-grey-1);
    }

    &.prio-0 {
        border: 1px solid color(p-blue-2);

        .label-component {
            color: color(p-link-color);
        }
    }
}
