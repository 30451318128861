@import "src/style/variables";

.label-component {
    font-family: "EloquiaDisplay Regular";
}

.fs-big {
    font-size: 42px !important;
}

.fs-h1, h1 {
    font-size: 28px;
    font-weight: 400;
    line-height: 1.500em;
}

.fs-h2, h2 {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5em;
}

.fs-h3, h3 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5em;
}

.fs-h4, h4 {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.250em;
}

.fs-h5, h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.250em;
}

.fs-h6, h6 {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.250em;
}

.fs-h7{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.250em;   
}

.fs-body-1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5em;
}

.fs-body-2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5em;
}

.fs-body-3 {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.250em;
}

.fs-body-4 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5em;
}

.fs-body-5 {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5em;
}

.fs-small-1 {
    font-family: "EloquiaText Bold";
    font-size: 12px;
    font-weight: 700;
    line-height: 1em;
}

.fs-small-2 {
    font-family: "EloquiaText Bold";
    font-size: 10px;
    font-weight: 700;
    line-height: 1em;
}

.fw-extra-bold {
    font-family: "EloquiaText Black";
}

.fw-bold {
    font-family: "EloquiaText Bold";
}

.fw-semi-bold {
    font-family: "EloquiaText SemiBold";
}

.fw-medium {
    font-family: "EloquiaText Medium";
}

.fw-regular {
    font-family: "EloquiaText Regular";
}

.fw-normal {
    font-family: "EloquiaText Regular";
    font-weight: normal;
}

.fw-light {
    font-family: "EloquiaText Light";
}

.ft-display {
    &.fs-small-1 {
        font-family: "EloquiaDisplay Bold";
    }
    
    &.fs-small-2 {
        font-family: "EloquiaDisplay Bold";
    }
    
    &.fw-extra-bold {
        font-family: "EloquiaDisplay Black";
    }
    
    &.fw-bold {
        font-family: "EloquiaDisplay Bold";
    }
    
    &.fw-semi-bold {
        font-family: "EloquiaDisplay SemiBold";
    }
    
    &.fw-medium {
        font-family: "EloquiaDisplay Medium";
    }
    
    &.fw-regular {
        font-family: "EloquiaDisplay Regular";
    }
    
    &.fw-normal {
        font-family: "EloquiaDisplay Regular";
    }
    
    &.fw-light {
        font-family: "EloquiaDisplay Light";
    }
}

.label-component {
    &.uppercase {
        text-transform: uppercase;
    }

    &.centerText {
        text-align: center;
    }
}

@each $color, $hex in $colors {
    .fc-#{"" + $color} {
        color: $hex;
    }
}

@media screen and (max-width: $vmPhoneLandscape) {
    .fs-big {
        font-size: 34px !important;
    }
}
