@import "style/main.scss";

html {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;

    min-height: 100vh;
    min-height: calc(100 * var(--vh, 1vh));
}

html,
body {
    font-family: "EloquiaDisplay Regular", sans-serif;
    scroll-behavior: smooth;
}

/* scrollbar style for firefox */
@supports (-moz-appearance:none) {
    * {
        scrollbar-width: thin;
    }

    body * {
        scrollbar-color: transparent transparent;
    }

    body *:hover {
        scrollbar-color: auto;
    }
}

html {
    scroll-behavior: smooth;
}

body {
    * {
        /* scroll width */
        ::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }

        /* scroll Track */
        // ::-webkit-scrollbar-track {
        //     background: #eaeaea;
        // }

        /* scroll Handle */
        ::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 5px;
        }

        /* scroll Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        *:hover::-webkit-scrollbar-thumb {
            background: #888;
        }
    }
}

h1 {
    margin: 0;
}

.visage-box-shadow {
    //box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.02), inset 0 0 0 1px rgba(0, 0, 0, 0.075);
    box-shadow: 0px 10px 74px rgba(197, 236, 235, 0.3);
}