@import "./../../style/variables.scss";

.VectorForm {
    .question-group {
        border: 1px solid color(p-border-color);
        padding: $gutterMedium;
        margin-bottom: $gutterMedium;

        .question-group-header {
            display: flex;
            flex-direction: row;
            align-items: center;

            >.label-component {
                flex-grow: 1;
            }
        }

        .question-group-content {
            display: flex;
            flex-direction: row;
            gap: $gutterMedium;

            > * {
                &:first-child {
                    width: 100px;
                    flex-grow: 1;
                }
                &:last-child {
                    width: 100px;
                }
            }
        }

        .FormControl {
            margin-bottom: 0px !important;
        }
    }
}