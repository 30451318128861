$colors: (
    p-black: #000,
    p-white: #FFF,
    p-dark-grey-1: #4e4f6c,
    p-dark-grey-2: #363853,
    p-dark-grey-3: #091B3D,
    p-mid-grey-1: #727E94,
    p-mid-grey-2: #707070,
    p-mid-grey-3: #566176,
    p-mid-grey-4: #6D7588,
    p-mid-grey-5: #4E4F6C,
    p-light-grey-1: #F4F4F5,
    p-light-grey-2: #d5d5d5,
    p-light-grey-3: #EAEAEA,
    p-light-grey-4: #E9E9E9,
    p-light-grey-5: #d0d5dd,
    p-light-grey-6: #A7A7B5,
    p-light-grey-7: #f5f5f5,
    p-light-grey-8: #9da4b1,
    p-light-grey-9: #ececec,
    p-light-grey-10: #909090,
    p-light-grey-11: #F6F6F6,
    p-light-grey-12: #f1f2ff,
    p-light-grey-13: #EAECF0,
    p-link-color: #787cdd,
    p-red-1: #fc5371,
    p-red-2: #E45864,
    p-red-3: #ff1429,
    p-red-4: #ED694E,
    p-red-5: #ff5555,
    p-red-6: #ea3c00,
    p-green-1: #00A578,
    p-green-2: #4B9F47,
    p-green-3: #eaf4ef,
    p-yellow-1: #ADA000,
    p-yellow-2: #F7D48B,
    p-yellow-3: #F1AE15,
    p-yellow-4: #FCB147,
    p-pink-1: #FBB5D4,
    p-pink-2: #fceef5,
    p-blue-1: #2772F0,
    p-blue-2: #C9CBF1,
    p-blue-3: #f1f2fc,
    p-border-color: #ECF0F3,
    p-outline-color: #488dc8
);

@function color($color) {
    @return map-get($colors, $color);
}

$borderRadiusSharper: 5px;
$borderRadius: 9px;
$borderRadiusRound: 12px;
$borderRadiusRound2: 16px;
$borderRadiusRound3: 20px;
$borderRadiusRound4: 24px;
$borderRadiusRounder: 30px;
$borderRadiusRounder2: 40px;

$gutterTiny: 2px;
$gutterXSmall: 4px;
$gutterSmaller: 8px;
$gutterSmall: 12px;
$gutterMedium: 16px;
$gutterLarge: 20px;
$gutterXLarge: 20px;
$gutterXXLarge: 24px;
$gutterXXLarge2: 30px;
$gutterXXXLarge: 45px;
$gutterXXXLargeInverted: -45px;

$label-height: 38px;
$line-width: 50px;
$line-height: 30px;

$font: EloquiaDisplay Regular, sans-serif;
$secondaryFont: EloquiaDisplay Regular, sans-serif;

$rightPanelWidth: 255px;

$spintrSidebarCollapsedWidth: 75px;
$spintrSidebarMaxWidth: 250px;
$spintrSidebarMinWidth: 216px;
$spintrSidebarExtendedWidth: 400px;
$spintrSidebarItemHeight: 32px;

/**
 * Header sizes
 */

$spintrHeaderHeight: 94px;

/**
 * Circle sizes
 */

$circleSmall: 32px;
$circleMedium: 36px;
$circleLarge: 44px;
$circleXLarge: 54px;

/**
 * Z-Index Table
 */
$spintrSidebarZIndex: 1;
$spintrHeaderZIndex: 36;
$spintrHeaderSearchZIndex: 37;
$spintrOverlayZIndex: 40;
$spintrOverlayContentZIndex: 41;
$spintrComposerOverlay: 50;
$spintrComposerContainer: 51;

/*
Transitions
*/
$bgColorTransition: background-color 250ms;
$fastTransition: 250ms;

/* 
Responsive breakpoints:

Startpage:
 * 1509: Start scaling down social feed
 * 1439: Stop scaling social feed, start scaling information feed
 * 1280: Reduce sidebar width from spintrSidebarMaxWidth to spintrSidebarMinWidth
*/

$vmTabletPortrait: 768px;
$vmPhoneLandscape: 960px;
$vmTabletLandscape: 1024px;

$responsiveHeaderHeight: 64px;

$socialFeedWidth: 500px;
$socialFeedMinWidth: 390px;

$modulesAreaMaxWidth: 850px;

$rightColumnMaxWidth: 225px;
$rightColumnMinWidth: 225px;

$commandBarHeight: 48px;