@import "src/style/variables.scss";

.spintrCustomDialog {
    .spintrCustomDialogInner {
        padding: 0;

        .ms-Dialog-inner {
            padding: $gutterXXLarge;
            padding-top: 0;
        }

        .spintrCustomDialogMessageWrap {
            margin-top: $gutterMedium;
            padding-bottom: $gutterXSmall;
        }

        .ms-Dialog-title {
            // padding: $gutterMedium;
            padding-bottom: 0;
            font-size: 20px;
            font-weight: 500;
            line-height: 1.5em;
            padding-left: $gutterLarge;
        }

        .ms-Dialog-subText {
            margin-bottom: $gutterMedium;
        }
    }
}
