@import "src/style/variables";

.ProjectForm {
    .ms-ChoiceFieldGroup-flexContainer {
        display: flex;
        flex-direction: row;
        gap: $gutterXXLarge;
    }

    .table {
        display: flex;
        flex-direction: column;

        .row {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid color(p-border-color);

            .cell {
                padding: $gutterSmall $gutterMedium;
                width: 187px;
                box-sizing: border-box;

                .label-component {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &.header {
                background-color: color(p-light-grey-1);
                padding-right: 92px;
                border-bottom: 0;
            }

            .actions {
                display: flex;
                flex-direction: row;
                gap: $gutterXXLarge;
                padding: $gutterSmall $gutterMedium;
                align-items: center;
                align-content: center;
                justify-content: flex-end;
                width: 96px;

                .unstyled-button {
                    height: 16px;

                    &:hover {
                        opacity: 0.75;
                    }
                }
            }
        }

        .table-footer {
            display: flex;
            flex-direction: column;
            gap: $gutterSmall;
            margin-top: $gutterSmall;

            > div {
                display: flex;
                flex-direction: row;
                align-items: center;

                > .label-component {
                    flex-grow: 1;
                }

                .SpintrLoader {
                    margin-left: $gutterMedium;
                    padding: 0px;
                }

                .ms-Button--primary {
                    background-color: color(p-green-3);
                    border: 1px solid color(p-green-3);

                    span {
                        color: color(p-green-1);
                    }

                    &.is-disabled {
                        span {
                            color: color(p-mid-grey-1);
                        }
                    }

                    &:not(.is-disabled) {
                        &:hover {
                            opacity: 0.75;
                        }
                    }
                }
            }
        }
    }

    &.questions {
        .table {
            .cell {
                width: auto;
                flex-grow: 1;

                .label-component {
                    white-space: normal;
                }
            }
        }

        .questions-footer {
            margin-top: $gutterXXXLarge;
        }
    }

    .add-button {
        display: flex;
        flex-direction: row;
        gap: $gutterSmaller;
        align-items: center;
        align-content: center;

        &:not(.disabled) {
            &:hover {
                opacity: 0.75;
            }
        }

        &.disabled {
            .Visage2Icon {
                svg {
                    * {
                        fill: color(p-mid-grey-2);
                    }
                }
            }

            .label-component {
                color: color(p-mid-grey-2);
            }
        }
    }

    .ChartFooterButtons {
        position: relative;
        padding: 0px;
        margin-top: $gutterMedium;
        justify-content: flex-end;
    }
}

.no-questions {
    margin-top: $gutterSmall;
}

.bottom-buttons {
    margin-top: $gutterSmall;
}