@import "src/style/variables.scss";

.ChartFooterButtons {
    position: absolute;
    bottom: 0px;
    right: 0px;
    padding: $gutterSmall;
    display: flex;
    flex-direction: row;
    gap: $gutterMedium;
    
    .button-content {
        display: flex;
        flex-direction: row;
        align-items: center;

        .Visage2Icon {
            margin-right: $gutterXSmall;
        }
    }
}