@import "src/style/variables.scss";

.spintr-list {
    &.isEmpty {
        .ms-DetailsList-contentWrapper {
            .ms-FocusZone {
                // display: none; on contentWrapper displays scrollbar in IE11
                display: block;
                background-color: color(p-light-grey-1);
            }
        }
    }

    &.disableSort {
        i[data-icon-name="SortUp"],
        i[data-icon-name="SortDown"] {
            display: none;
        }
    }

    .ms-List-cell {
        border-bottom: 1px solid color(p-border-color);

        &:last-child {
            border-bottom: none
        }
    }
}

.spintr-list-empty-list {
    .spintr-list-empty-list-label {
        padding: $gutterMedium;
        text-align: center;
        font-weight: 400;
        background-color: color(p-white);
    }
}

.ms-DetailsRow {
    &.is-selected {
        background-color: color(p-light-grey-1);
    }
}

.textNoTodo {
    padding: $gutterXXXLarge;
    text-align: center;
}

.ms-DetailsHeader{
    padding-top:0;
    border-bottom: 0;
    background-color: color(p-light-grey-1);
    height: 32px;
    line-height: 32px;

    .ms-DetailsHeader-cell {
        background-color: color(p-light-grey-1);
        padding-left: $gutterMedium;
        height: 32px;

        &:active, &:hover {
            background-color: color(p-light-grey-1);
        }
    }
}

.ms-DetailsRow-cell > .ms-Link {
    color: color(p-link-color) !important;
}