@import "src/style/variables.scss";

.radar-view {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    .radar-view-content {
        display: flex;
        flex-grow: 1;
        position: relative;
    }

    .box-view {
        padding: $gutterXXLarge;

        .boxes {
            display: flex;
            flex-direction: row;
            gap: $gutterMedium;
            flex-wrap: wrap;
    
            .box {
                padding: $gutterMedium;
                border-radius: $borderRadius;
                border: 1px solid color(p-border-color);
                width: 150px;
            }
        }
    }

    .radar-right {
        max-width: 257px;
        width: 257px;
        flex-grow: 1;
        border-left: 1px solid color(p-border-color);
        padding: $gutterXXLarge;
        box-sizing: border-box;
        overflow-y: scroll;
        max-height: calc(100vh - 97px);

        > .label-component {
          margin-bottom: $gutterSmall;
        }

        .survey-wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: $gutterSmaller;
            margin-bottom: $gutterSmall;
            padding-bottom: $gutterSmall;
            border-bottom: 1px solid color(p-border-color);

            &:last-child {
              border-bottom: 0;
            }

            .row {
                display: flex;
                flex-direction: row;
                gap: $gutterSmaller;
                align-items: center;
            }

            .survey-dot {
                width: 12px;
                height: 12px;
                border-radius: 100%;
            }

            .survey-action {
                padding: 6px;
                border-radius: 100%;
                transition: var(--bg-transition);
          
                &:hover {
                    background-color: var(--gray-lighter);
                }
            }

            .unstyled-button {
                padding: $gutterXSmall;
            }
        }
    }

    .header-wrapper {
        display: flex;
        flex-direction: row;

        .pageHeader {
            flex-grow: 1;
        }
    }

    .vectorName {
        &:hover {
            color: color(linkColor);
            font-weight: 550;
        }
    }

    .radar-view-graph {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        align-items: center;
        -webkit-animation: fadein 0.5s;
        /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 0.5s;
        /* Firefox < 16 */
        -ms-animation: fadein 0.5s;
        /* Internet Explorer */
        -o-animation: fadein 0.5s;
        /* Opera < 12.1 */
        animation: fadein 0.5s;
        -webkit-animation: fadeInFromNone 0.5s ease-out;
        -moz-animation: fadeInFromNone 0.5s ease-out;
        -o-animation: fadeInFromNone 0.5s ease-out;
        animation: fadeInFromNone 0.5s ease-out;
        align-items: center;
        justify-content: center;

        .canvas-wrapper {
            position: relative;
            height: 65vh;
            width: 65vh;
            max-width: calc(100vw - 255px - 255px - 450px);
        }

        .graph-toggles {
            width: 169px;
            margin-left: $gutterXLarge;

            .graph-toggle {
                margin-bottom: $gutterMedium;
                display: flex;
                flex-direction: row;
                align-items: center;

                .legend-circle {
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    margin-right: $gutterXSmall;
                }

                .ms-Toggle {
                    margin-left: $gutterMedium;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .chart-container {
        overflow: hidden;
        position: relative;
        margin-right: $gutterXXXLarge;

        &.datalabel-count-3 {
            margin-bottom: -7%;
        }

        &.datalabel-count-5 {
            margin-bottom: -2%;
        }
    }

    .legend-stack {
        .legend-circle {
            width: 12px;
            height: 12px;
            border-radius: 100%;

            margin-right: $gutterXSmall;
        }

        .label-component {
            margin-right: $gutterLarge;
        }
    }

    .radar-footer {
        display: flex;
        justify-content: space-between;
        -webkit-animation: fadein 0.5s;
        /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 0.5s;
        /* Firefox < 16 */
        -ms-animation: fadein 0.5s;
        /* Internet Explorer */
        -o-animation: fadein 0.5s;
        /* Opera < 12.1 */
        animation: fadein 0.5s;
    }
}


  
.chart-label-wrapper {
    display: flex;
    position: absolute;

    .line-wrapper {
        height: max-content;

        img {
            display: none;
        }
    }

    .chart-label {
        background-color: color(p-white);
        max-width: 170px;
        min-height: $label-height;
        width: max-content;
        padding: 0 14px;
        cursor: pointer;
        border-radius: 30px;
        border: 1px solid color(p-light-grey-6);
        word-break: break-word;

        .label-component {
            padding: $gutterXSmall 0;
        }
    }

    &.top {
        transform: translateY(-100%);
        .line-wrapper {
            img:nth-of-type(1) {
                display: block;
                width: $line-width;
                margin-top: calc($label-height / 2);
            }
        }

        &.invert {
            flex-direction: row-reverse;
            transform: translateY(-100%) translateX(-100%);
            .line-wrapper {
                img:nth-of-type(1) {
                    transform: scaleX(-1);
                }
            }
        }

        .container-wrapper {
            align-self: flex-start;
        }
    }
    &.right {
        .line-wrapper {
            img:nth-of-type(2) {
                height: $line-height;
                display: block;
                margin-right: -25px;
            }
        }
        .chart-label {
            margin-top: $line-height;
        }
    }
    &.left {
        flex-direction: column;
        align-items: flex-end;
        transform: translate(-100%,  calc(-1 * $label-height / 2));
        .line-wrapper {
            img:nth-of-type(4) {
                height: $line-height;
                display: block;
            }
        }
        .chart-label {
            margin-top: calc(-2 * $line-height);
            margin-right: 25px;
        }
    }

    &.bottom {
        &.bottom-left {
            flex-direction: row-reverse;
            transform: translateX(-100%);
            .line-wrapper {
                img:nth-of-type(3) {
                    display: block;
                    width: $line-width;
                }
            }
        }

        &.bottom-right {
            .line-wrapper {
                img:nth-of-type(3) {
                    transform: scaleX(-1);
                    display: block;
                    width: $line-width;
                }
            }
        }

        .chart-label {
            margin-top: calc($line-height / 2);
        }
    }
}


@-webkit-keyframes fadeInFromNone {
    0% { opacity: 0; }
    75% { opacity: 0; }
    100% { opacity: 1; }
}

@-moz-keyframes fadeInFromNone {
    0% { opacity: 0; }
    75% { opacity: 0; }
    100% { opacity: 1; }
}

@-o-keyframes fadeInFromNone {
    0% { opacity: 0; }
    75% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeInFromNone {
    0% { opacity: 0; }
    75% { opacity: 0; }
    100% { opacity: 1; }
}
