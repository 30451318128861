@import "src/style/variables";

.initialsGreyBackground {
    .ms-Persona-initials {
        background-color: color("p-light-grey-1") !important;

        span {
            color: color("p-dark-grey-1");
        }
    }
}

.ms-Persona {
    &.big {
        .ms-Persona-details {
            padding-left: $gutterSmall;
        }

        .ms-Persona-primaryText {
            font-size: 14px;
            font-family: "EloquiaDisplay Medium";
        }

        .ms-Persona-secondaryText {
            font-size: 14px;
        }
    }
}