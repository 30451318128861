@import "src/style/variables";

.PopupHeader {
    .PopupHeader-inner {
        padding: $gutterXXLarge;
        padding-top: $gutterXLarge;
        padding-bottom: $gutterXLarge;
        border-bottom: 1px solid #eaeaea;
        margin-bottom: $gutterXLarge;

        .top-row {
            display: flex;

            .back-button {
                height: 24px;
                margin-right: $gutterSmall;
            }

            .label-component {
                flex-grow: 1;
                line-height: 24px;
            }

            .close-button {
                height: 24px;
                margin-left: $gutterSmall;

                i {
                    transition: color 0.3s;
                    color: color(p-mid-grey-3);
                    line-height: 24px;
                }

                &:hover {
                    i {
                        color: color(p-dark-grey-1);
                    }
                }
            }
        }
    }

    .sub-text {
        padding: 0 $gutterXXLarge $gutterXXLarge $gutterXXLarge;
    }

    &.has-footer-content {
        .PopupHeader-inner {
            border-radius: $borderRadiusRound3;

            .footer {
                margin-top: $gutterSmaller;
            }
        }
    }
}

.dialogWithPopupHeader {
    .ms-Dialog-header, .ms-Dialog-subText {
        display: none;
    }

    .PopupHeader {
        margin: 0 calc(#{$gutterXXLarge} * -1);
    }
}

.modalWithPopupHeader {
    .ms-Dialog-header, .ms-Dialog-subText {
        display: none;
    }

    .PopupHeader {
        margin: calc(#{$gutterXXLarge} * -1) calc(#{$gutterXXLarge} * -1) 0 calc(#{$gutterXXLarge} * -1);
    }
}