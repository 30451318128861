.unstyled-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;

    user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    &.inline {
      display: inline-block;
    }

    &.disabled {
      cursor: default;
    }
}

.ms-Fabric--isFocusHidden {
  .unstyled-button {
    outline: none;
  }
  .unstyled-button :focus {
    outline: none;
  }
}