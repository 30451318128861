@charset "utf-8";

@font-face {
    font-family: "EloquiaDisplay ExtraBold";
    src: url("fonts/EloquiaDisplay-ExtraBold.otf");
}

@font-face {
    font-family: "EloquiaDisplay Black";
    src: url("fonts/EloquiaDisplay-Black.otf");
}

@font-face {
    font-family: "EloquiaDisplay Bold";
    src: url("fonts/EloquiaDisplay-Bold.otf");
}

@font-face {
    font-family: "EloquiaDisplay Italic";
    src: url("fonts/EloquiaDisplay-Italic.otf");
}

@font-face {
    font-family: "EloquiaDisplay Light";
    src: url("fonts/EloquiaDisplay-Light.otf");
}

@font-face {
    font-family: "EloquiaDisplay Medium";
    src: url("fonts/EloquiaDisplay-Medium.otf");
}

@font-face {
    font-family: "EloquiaDisplay Regular";
    src: url("fonts/EloquiaDisplay-Regular.otf");
}

@font-face {
    font-family: "EloquiaDisplay SemiBold";
    src: url("fonts/EloquiaDisplay-SemiBold.otf");
}

@font-face {
    font-family: "EloquiaDisplay Thin";
    src: url("fonts/EloquiaDisplay-Thin.otf");
}

@font-face {
    font-family: "EloquiaText Bold";
    src: url("fonts/EloquiaText-Bold.otf");
}

@font-face {
    font-family: "EloquiaText Italic";
    src: url("fonts/EloquiaText-Italic.otf");
}

@font-face {
    font-family: "EloquiaText Light";
    src: url("fonts/EloquiaText-Light.otf");
}

@font-face {
    font-family: "EloquiaText Medium";
    src: url("fonts/EloquiaText-Medium.otf");
}

@font-face {
    font-family: "EloquiaText Regular";
    src: url("fonts/EloquiaText-Regular.otf");
}

@font-face {
    font-family: "EloquiaText SemiBold";
    src: url("fonts/EloquiaText-SemiBold.otf");
}