@import "src/style/variables";

.pageHeader {
    position: relative;
    margin-bottom: $gutterMedium;
    display: flex;
    flex-direction: row;

    >.left {
        flex-grow: 1;
    }

    // > * {
    //     max-width: 100%;

    //     > * {
    //         max-width: 100%;
    //     }
    // }

    .pageHeader-title {
        h4 {
            margin-top: 0;
            margin-bottom: 0;
            display: inline-block;
            margin-right: $gutterXSmall;
        }
    }

    .pageHeader-subText {
        padding-top: $gutterXSmall;
    }

    .actionmenu-component,
    .StandardActionMenu {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .StandardActionMenu {
        top: calc(50% - 12px);
    }
}