@import "src/style/variables.scss";

.textDanger{
    color:#a94442;
}
.obligatoryInfoText{
    margin-bottom: $gutterXSmall;
}

.ms-Modal > .ms-Dialog-main > .ms-Modal-scrollableContent {
    overflow-y: visible;
}

.ms-ContextualMenu {
    &.hasSpintrIcons {
        .ms-ContextualMenu-header {
            padding-left: 4px;
        }

        .ms-ContextualMenu-link {
            padding-left: $gutterSmall;
        }

        .ms-ContextualMenu-linkContent {
            .Visage2Icon {
                height: 16px;
                margin-top: -2px;

                svg {
                    display: block;
                }
            }

            .ms-ContextualMenu-itemText {
                margin-left: $gutterSmall;
            }
        }
    }
}

.ms-ContextualMenu-link{
    -webkit-transition: background-color $fastTransition;
    -moz-transition:  background-color $fastTransition;
    -o-transition:  background-color $fastTransition;
    transition:  background-color $fastTransition;
}

.ms-CommandBar-primaryCommand {
    .ms-OverflowSet-item {
        .actionmenu-component {
            border: none;
        }

        border: 1px solid #d5d5d5;
        margin-right: $gutterSmall;
    }
}

.ms-Persona {
    &.no-subtext {
        .ms-Persona-secondaryText {
            display: none;
        }
    }
}

.ms-Persona-primaryText {
    font-weight: 400;
}

.ms-Persona-details {
    padding-left: $gutterSmaller;
}

.ms-DetailsRow-fields {
    div:last-child {
        text-overflow: clip;
    }
}

.ms-DetailsRow-cell {
    padding: $gutterSmall $gutterMedium;
    color: color(p-dark-grey-1);
    box-sizing: border-box;
    min-height: 48px;
    display: flex;
    align-items: center;

    .actionmenu-component {
        display: block;
        margin-top: 6px;
        margin-right: $gutterSmall;
    }
}

.spintr-list{
    .ms-DetailsList {
        .ms-DetailsRow {
            &:hover {
                background-color: color(p-light-grey-1);
            }
        }
    }
}

.ms-DetailsList-contentWrapper .ms-DetailsRow-fields {
    line-height: 24px !important;
}

.ms-DetailsHeader-cell {
    padding-left: $gutterMedium;

    .ms-DetailsHeader-cellTitle {
        padding-left: $gutterMedium;
    }

    &:first-child {
        border-top-left-radius: $borderRadius;
        .ms-TooltipHost {
            border-top-left-radius: $borderRadius;
        }
    }

    &:last-child {
        border-top-right-radius: $borderRadius;
        .ms-TooltipHost {
            border-top-right-radius: $borderRadius;
        }
    }

    .ms-TooltipHost {
        &:hover {
            background-color: color(p-light-grey-1);
        }
    }

    .ms-DetailsHeader-cellName {
        color: color(p-mid-grey-1);
        font-weight: 500;
        font-size: 12px;
    }
}

.ms-Pivot-link{
    -webkit-transition: background-color $fastTransition;
    -moz-transition:  background-color $fastTransition;
    -o-transition:  background-color $fastTransition;
    transition:  background-color $fastTransition;
}

.ms-DetailsRow, .ms-DetailsHeader-cell{
    -webkit-transition: background-color $fastTransition;
    -moz-transition:  background-color $fastTransition;
    -o-transition:  background-color $fastTransition;
    transition:  background-color $fastTransition;
}

.searchBox {
    background-color: color(p-light-grey-1);
    color: color(p-dark-grey-3);
    border-radius: $borderRadiusRounder;
    border: solid 1px color(p-light-grey-9);
    height: 40px;
    margin: $gutterSmall $gutterLarge;
    padding-left: 0px;

    &:hover {
        border: solid 1px color(p-light-grey-9);
    }

    .ms-SearchBox {
        border: 0;
        background-color: transparent;
        margin-left: $gutterXSmall;
    }
}

.ms-CommandBar {
    margin-bottom: 14px;
    border-bottom: none;
    height: $commandBarHeight;

    .ms-Button{
        -webkit-transition: background-color $fastTransition;
        -moz-transition:  background-color $fastTransition;
        -o-transition:  background-color $fastTransition;
        transition:  background-color $fastTransition;
    }

    .ms-SearchBox {
        background-color: color(p-white);
        border: 0;
        border-radius: $borderRadiusSharper;

        &.is-active {
            border: none;
        }
    }

    .ms-OverflowSet-item {
        border: 0;
        border-radius: $borderRadiusRounder;
        height: $commandBarHeight;

        .ms-CommandBarItem-link {
            padding: 0 $gutterMedium;
        }

        button {
            border-radius: $borderRadiusRounder;
        }

        &:last-child {
            margin-right: 0 !important;
        }
    }
}

.ms-CommandBar-secondaryCommand {
    .ms-OverflowSet-item {
        margin-left: $gutterSmall;
    }
}

.CommandBar-GrowSearch {
    .ms-CommandBar {
        .ms-OverflowSet-item {
            &:first-child {
                flex-grow: 1;

                .ms-SearchBox {
                    width: 100%;
                }
            }
        }
    }
}

.ms-Pivot {
    .ms-Callout-container {
        z-index: 1;
    }
}

.tinymce{
    .tox-tinymce{
        border-color: color("p-mid-grey-2");
        border-radius: $borderRadiusSharper;
    }
}

$totalRigthPanelWidth: $rightPanelWidth + $gutterLarge;

.FormFooterBar {
    position: fixed;
    left: $spintrSidebarMaxWidth;
    right: 0px;
    bottom: 0px;
}

.VisageSideBarHidden {
    .FormFooterBar {
        left: 0;
    }
}

@media screen and (max-width: 1550px) {
    .FormFooterBar {
        left: $spintrSidebarMinWidth;
    }
}

.FormFooterBar{
    .ms-Button{
        -webkit-transition: background-color $fastTransition;
        -moz-transition:  background-color $fastTransition;
        -o-transition:  background-color $fastTransition;
        transition:  background-color $fastTransition;
    }
}

.ms-DetailsList-contentWrapper {
    .ms-DetailsRow-fields {
        font-size: 14px;
    }
}

.ms-DetailsList-contentWrapper .ms-DetailsRow-fields {
    line-height: 2.375em;
}

.dateInput {
    padding: 0.5em 0.5em 0.5em 2.5em;
    font-size: 12px;
}

.SpintrDatePicker {
    .react-datepicker__close-icon{
        height: auto;
        top: 10px;
    }
    .react-datepicker-wrapper {
        > .react-datepicker__input-container {
            input {
                min-width: 200px;
            }

            .react-datepicker__close-icon::after {
                background-color: color(p-light-grey-10);
            }
        }
    }
}

.SpintrDatePicker.time-only {
    .react-datepicker-wrapper {
        > .react-datepicker__input-container {
            input {
                min-width: 40px !important;
                width: 40px;
            }
        }
    }
}

i.fa-calendar {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
}

.ms-Button--primary {
    &:hover {
        opacity: 0.75;
    }
}

.ms-Button--primary, .ms-Button--default{
    -webkit-transition: background-color $fastTransition;
    -moz-transition:  background-color $fastTransition;
    -o-transition:  background-color $fastTransition;
    transition:  background-color $fastTransition;

    &.create-button {
        background-color: color(p-green-1);
        border: 1px solid color(p-green-1);

        .Visage2Icon-add {
            margin-right: $gutterSmaller;

            svg {
                path {
                    fill: color(p-white);
                }
            }
        }
    }
}



.react-datepicker-popper {
    // needs to be higher than tinymce toolbar for calendar event popup form
    z-index: 2;
}

.isSmallViewMode {
    .spintr-modal {
        .ms-Dialog-main {
            max-width: 100%;
        }
    }
}
.ms-BasePicker-text {
    min-height: 40px !important;
}

.ms-Dropdown {
    min-height: 40px;
    width: 100%;
    border-radius: $borderRadiusRound;

    span {
        border-color: color("p-mid-grey-2");
        min-height: 40px;
        line-height: 2.5em;
        border-radius: $borderRadiusRound;
    }
}

.linkFGColor {
    color: color("p-link-color");
}

.PivotItemWithActionMenu {
    .actionmenu-component {
        margin-left: $gutterXSmall;
    }
}

.general-row-break {
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    white-space: unset !important;
}

.raw-row-break {
    word-break: break-all;
}

.ms-Button {
    border-radius: $borderRadiusRounder;

    .ms-Button-textContainer {
        .ms-Button-label {
            font-family: "EloquiaDisplay Medium";
            font-weight: 400;
        }
    }

    .ms-Button-flexContainer {
        font-family: "EloquiaDisplay Medium";
        font-weight: 400;
    }
}

.ms-ContextualMenu-Callout {
    border-radius: $borderRadiusSharper;
}

.ms-ContextualMenu-link {
    padding-left: 8px;
}

@keyframes pulse {
    from {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    50% {
        transform: scale(1.05);
        -webkit-transform: scale(1.1);
    }
    75% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    to {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

@keyframes rotate {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(90deg); }
}

.icon-animation {
    &.animation-pulse:hover {
        .Visage2Icon {
            animation-name: pulse;
            animation-duration: .5s;
        }
    }

    &.animation-rotate:hover {
        .Visage2Icon {
            animation-name: rotate;
            animation-duration: .5s;
        }
    }
}