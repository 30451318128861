@import "./../../style/variables.scss";

.Coworkers {
    .user-item {
        display: flex;
        flex-direction: row;

        .lock-icon {
            color: color(p-dark-grey-1);
        }
    }
}