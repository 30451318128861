@import "src/style/variables";

.FileSelector {
  .FileSelector-inner {
    padding: $gutterXXLarge;
    max-width: 799px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: $borderRadiusRound;
    border: 1px solid color(p-light-grey-6);

    > * {
      text-align: center;

      .upload-button {
        height: $circleLarge;
        width: $circleLarge;
        border-radius: $borderRadius;
        border: 1px solid color(p-border-color);
        margin: 0 auto;
        margin-bottom: $gutterSmall;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;

        .Visage2Icon {
          display: block;
          height: 20px;
        }
      }

      .upload-text {
        display: flex;
        flex-direction: row;
        gap: 4px;
      }
    }

    img {
      max-height: calc(100% - (#{$gutterLarge} * 2));
      max-width: calc(100% - (#{$gutterLarge} * 2));
    }
  }

  .FileSelector-buttons {
    > * {
      margin-right: $gutterLarge;
    }
  }

  .FileSelector-content {
    margin-bottom: $gutterMedium;
    border-radius: $borderRadiusRound;

    > img {
      max-width: 200px;
      max-height: 200px;
      border-radius: $borderRadiusRound;
    }

    &.is-empty {
      cursor: pointer;
  
      &:hover {
        opacity: 0.75;
      }
  
      &.is-dragging-file-over {
        border-color: color(p-link-color);
        border-style: dashed;
        border-width: 3px;

        .FileSelector-inner {
          border: 0;
        }
      }
    }
  }
}