@import "src/style/variables";

.Page {
    .Page-content {
        margin: $gutterXXLarge;
        padding: $gutterXXLarge;
        border: 1px solid color(p-border-color);
        border-radius: $borderRadiusRound;
    }

    &.fullScreen {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
}
