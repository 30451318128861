@import "./../../style/variables.scss";

.InstanceSettings {
    .survey-checkboxes {
        .control {
            .ms-Checkbox {
                &:first-child {
                    margin-bottom: $gutterSmaller;
                }
            }
        }
    }
}