@import "src/style/variables";
@import "src/components/Label/Label.scss";

.register-view {
    .content {
        .form-container {
            .register-label {
                margin-top: 148px;
                margin-bottom: 8px;
            }

            .ms-TextField {
                margin-top: 24px;

                .ms-TextField-errorMessage {
                    @extend .fs-body-1;
                }
            }

            .role-company {
                display: flex;
                gap: 30px;

                .ms-TextField {
                    flex: 1 1 100%;
                }
            }

            .captcha-container {
                margin-top: 24px;
            }

            .next-button {
                width: 100%;
                height: 56px;
                margin-top: 24px;
                border-radius: 8px;

                .ms-Button-label {
                    @extend .fs-body-1;
                    @extend .fw-bold;
                }
            }
        }
    }
}

@media only screen and (max-width: 1023px) {
    .register-view {
        .content {
            .form-container {
                .role-company {
                    display: block;
                }
            }
        }
    }
}
