@import "./../../style/variables.scss";

.Plan {
    height: 100vh;

    .Page {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .bars {
        margin: 80px 24px;
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        gap: $gutterXSmall;
        position: relative;

        .bar {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            gap: $gutterXSmall;
            width: 25%;

            .bar-content {
                display: flex;
                flex-grow: 1;
                align-items: flex-end;
                height: calc(100vh - 350px);
                overflow-y: scroll;

                .main-goal-inner {
                    border-radius: $borderRadiusRound2;
                    display: flex;
                    flex-grow: 1;
                    align-items: flex-end;
                    height: 100%;

                    .label-component {
                        margin: $gutterXXLarge;
                    }
                }

                .bar-content-inner {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    height: 100%;
                    gap: $gutterXSmall;

                    .bar-item, .bar-create {
                        border-radius: $borderRadiusRound2;
                    }

                    .bar-create {
                        display: flex;
                        flex-grow: 1;
                        justify-content: center;
                        align-items: center;
                        align-content: center;

                        .unstyled-button {
                            padding: $gutterSmaller $gutterSmall;
                            background-color: color(p-white);
                            border-radius: $borderRadiusRounder2;
                            display: flex;
                            flex-direction: row;
                            gap: $gutterSmaller;
                            align-items: center;
                            align-content: center;
                            justify-content: center;
                            margin: $gutterMedium;

                            &:hover {
                                opacity: 0.75;
                            }
                        }
                    }

                    .bar-item {
                        padding: $gutterXXLarge;
                        text-align: center;
                        cursor: pointer;
                        position: relative;
                        transition: all 0.3s ease-in-out;

                        .edit-button {
                            position: absolute;
                            top: $gutterSmall;
                            right: $gutterSmall;
                            display: none;
                        }

                        &:hover {
                            .edit-button {
                                display: block;
                            }
                        }
                    }
                }
            }

            .bar-footer {
                border-radius: $borderRadiusRound2;
                padding: $gutterXXLarge2 $gutterXXLarge;
                text-align: center;
            }

            &:nth-child(1) {
                .main-goal-inner {
                    background-color: #fafcfb;
                }
                .bar-footer {
                    background-color: #EAF4EF;
                }
            }

            &:nth-child(2) {
                .bar-create, .bar-item {
                    background-color: #fffbf5;
                }
                .bar-item {
                    &:hover, &.active {
                        background-color: #fdefd2;
                    }
                }
                .bar-footer {
                    background-color: #ffefd5;
                }
            }

            &:nth-child(3) {
                .bar-create, .bar-item {
                    background-color: #f8f9fd;
                }
                .bar-item {
                    &:hover, &.active {
                        background-color: #babced;
                    }
                }
                .bar-footer {
                    background-color: #f1f2fc;
                }
            }

            &:nth-child(4) {
                .bar-create, .bar-item {
                    background-color: #fde1ee;
                }
                .bar-item {
                    &:hover, &.active {
                        background-color: #fd98aa;
                    }
                }
                .bar-footer {
                    background-color: #fef7fa;
                }
            }
        }
    }
}

.Plan {
    .half-circle {
        min-width: 1000px;
        padding-bottom: 50px;
        overflow: hidden;

        canvas {
            margin: -100px auto -250px;
            width: 1000px;
            max-width: 1000px;
            min-width: 1000px;

            height: 100vh;
            width: 100vw;
        }

        .chart-footer {
            width: 1000px;
            max-width: 1000px;
            min-width: 1000px;
            margin: 0 auto;
            display: flex;
            flex-direction: row;

            >* {
                display: flex;
                flex-grow: 0;
                flex-shrink: 0;
                height: 60px;
                padding: $gutterSmall;
                box-sizing: border-box;
                align-items: center;
                justify-content: center;
                align-content: center;
                border-radius: 20px;

                &:nth-child(odd) {
                    background-color: #F8F7F5;
                    border: 2px solid color(p-white);
                    width: 126px;
                }

                &:nth-child(even) {
                    background-color: #F4EDF4;
                    border: 1px solid color(p-white);
                    width: 124px;
                }

                &.middle {
                    width: 248px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .Plan {
        .half-circle {
            min-width: 800px;

            canvas {
                margin: -100px auto -200px;
                width: 800px;
                max-width: 800px;
                min-width: 800px;
            }

            .chart-footer {
                width: 800px;
                max-width: 800px;
                min-width: 800px;

                >* {
                    &:nth-child(odd) {
                        border: 2px solid color(p-white);
                        width: 100px;
                    }

                    &:nth-child(even) {
                        border: 1px solid color(p-white);
                        width: 100px;
                    }

                    &.middle {
                        width: 200px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .Plan {
        .half-circle {
            min-width: 600px;

            canvas {
                margin: -100px auto -150px;
                width: 600px;
                max-width: 600px;
                min-width: 600px;
            }

            .chart-footer {
                width: 600px;
                max-width: 600px;
                min-width: 600px;

                >* {
                    &:nth-child(odd) {
                        border: 2px solid color(p-white);
                        width: 75px;
                    }

                    &:nth-child(even) {
                        border: 1px solid color(p-white);
                        width: 75px;
                    }

                    &.middle {
                        width: 150px;
                    }

                    .label-component {
                        font-size: 8px;
                    }
                }
            }
        }
    }
}

.plan-switcher {
    border: 1px solid color(p-light-grey-5);
    border-radius: $borderRadiusRounder;
    display: flex;
    flex-direction: row;

    .unstyled-button {
        background-color: #FFFFFF;
        padding: $gutterSmaller $gutterMedium;
        height: 40px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:first-child {
            border-top-left-radius: $borderRadiusRounder;
            border-bottom-left-radius: $borderRadiusRounder;
            border-right: 1px solid color(p-light-grey-5);
        }

        &:last-child {
            border-top-right-radius: $borderRadiusRounder;
            border-bottom-right-radius: $borderRadiusRounder;
        }

        &.active {
            background-color: color(p-blue-3);
        }

        &:hover {
            opacity: 0.75;
        }
    }
}