@import "src/style/variables.scss";

.FormSection {
    margin-bottom: $gutterXXLarge;
    box-sizing: border-box;

    .ms-TextField-wrapper {
        .ms-TextField-fieldGroup {
            min-height: 40px; // also used in multiline!!
            //border-color: color("p-mid-grey-2");
            border-radius: $borderRadiusRound;
        }
    }

    > .label-component {
        margin-bottom: $gutterXSmall;
    }

    .FormControl {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}
