@import "./../../style/variables.scss";

.sidebar {
    width: 255px;
    background-color: color(p-white);
    flex-shrink: 0;
    border-right: 1px solid color(p-border-color);

    .logo-wrapper {
        display: flex;
        align-items: center;
        width: 124px;
        padding: $gutterXXLarge;

        img {
            max-width: 100%;
            box-sizing: border-box;
            max-height: 100%;
        }
    }

    .menu {
        width: 255px;
        height: 100vh;
        position: fixed;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        display: flex;
        flex-direction: column;

        &::-webkit-scrollbar {
            display: none;
        }

        .menu-items {
            margin-top: $gutterSmaller;
            flex-grow: 1;

            .SidebarItem-border {
                height: 1px;
                width: calc(100% - (#{$gutterXXLarge} * 2));
                background-color: color(p-border-color);
                margin: $gutterSmall $gutterXXLarge;
                box-sizing: border-box;
            }

            .SidebarItem {
                display: block;
                margin: $gutterXSmall $gutterXXLarge 0px $gutterXXLarge;
                padding: $gutterSmaller;
                transition: background-color 0.3s ease-in-out;
                display: flex;
                flex-direction: row;
                gap: $gutterSmaller;
                align-items: center;
                border-radius: $borderRadius;

                &.isActive {
                    background-color: color(p-light-grey-1);
                }

                &:not(.isActive) {
                    &:hover {
                        background-color: color(p-light-grey-4);
                    }
                }

                .SidebarItemIcon {
                    height: 20px;
                }

                .label-component {
                    position: relative;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex-grow: 1;
                }
            }
        }

        .SpintrUserMenu {
            padding: $gutterXXLarge;
        }
    }
}