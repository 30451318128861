@import "src/style/variables";
@import "src/components/Label/Label.scss";

.split-auth-view {
    .error-bar {
        background-color: color(p-red-5);
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
    }

    .content {
        display: flex;
        min-height: 100vh;
        flex: 1 1 100%;

        &.has-error {
            .left {
                margin-top: 60px;
            }
        }

        .left,
        .right {
            flex: 1 0 50%;

            .background-image {
                width: 100%;
                height: 100%;
                background-position: center;
            }
        }

        .form-container {
            max-width: 620px;
            margin: 0 auto;
            padding: 0 20px;
            margin-bottom: 30px;

            .login-logo-wrapper {
                height: 28px;
                display: flex;
                margin-top: 50px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    box-sizing: border-box;
                }
            }

            .ms-TextField {
                label {
                    @extend .fs-body-5;
                    @extend .fw-medium;
                    padding-bottom: 12px;
                }

                .ms-TextField-fieldGroup {
                    min-height: 58px;
                    border: 1px solid #d6dce6;
                    border-radius: 8px;

                    input {
                        @extend .fs-body-1;
                    }

                    .ms-TextField-reveal {
                        height: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1023px) {
    .split-auth-view {
        .right {
            display: none;
        }
    }
}
