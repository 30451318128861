@import "./../../style/variables.scss";

.Surveys {
    .send-reminder-button {
        margin-top: 6px;
        height: 38px;
        padding: 0px 16px !important;

        &.sent {
            background-color: color(p-link-color);
            border-color: color(p-link-color);
            pointer-events: none;
            cursor: default;
        }

        &:hover {
            opacity: 0.75;
        }
    }

    .survey-comments-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        gap: $gutterSmaller;
    }
}

.dispatch-info {
    display: block;

    > * {
        display: inline;
    }
}