@import "./../../style/variables.scss";

.AdvancedSettings {
    .ms-DetailsRow-cell {
        .unstyled-button {
            position: relative;
            top: -2px;

            > * {
                display: inline-block;
                vertical-align: middle;
            }

            .Visage2Icon {
                display: inline-block;
                height: 20px;
                margin-left: $gutterXSmall;

                svg {
                    display: block;
                }
            }
        }
    }
}