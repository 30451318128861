@import "src/style/variables";

.Header {
    border-bottom: 1px solid color(p-border-color);
    padding: $gutterXXLarge;
    display: flex;
    flex-direction: row;

    .header-inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 1;
        gap: $gutterXXLarge;

        .header-left, .header-right {
            display: flex;
            flex-direction: row;
            gap: $gutterMedium;
            flex-grow: 1;
        }

        .header-left {
            .pageHeader {
                margin-bottom: 0px;
            }
        }

        .header-right {
            justify-content: flex-end;
            align-items: center;
        }

        .ms-Dropdown-title {
            border-color: color(p-light-grey-5);
        }
    }
}
