@import "src/style/variables";

.ColorPicker {
  display: flex;
  flex-direction: row;
  border: 1px solid color(p-border-color);
  padding: $gutterXSmall;
  justify-content: center;
  border-radius: $borderRadiusSharper;
  cursor: pointer;

  .label-component {
    flex-grow: 1;
    margin: 0;
    display: flex;
    align-items: center;
    margin-left: $gutterMedium;
  }

  .color-circle {
    height: $circleLarge;
    width: $circleLarge;
    border-radius: $circleLarge;
  }
}