@import "./../../style/variables.scss";

.SurveyView-loader {
    display: flex;
    height: 100vh;
    align-items: center;
}

.anon-banner {
    background-color: color(p-light-grey-1);
    padding: $gutterMedium;
    text-align: center;
}

.SurveyView {
    background-color: #FFF;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .SurveyViewHeader {
        width: 100%;
        z-index: 1;
        position: relative;
        background-color: #FFF;

        .SurveyViewHeader-inner {
            display: flex;
            flex-direction: row;
            margin: 0 auto;
            border-bottom: 1px solid color(p-border-color);

            .logo-wrapper {
                display: flex;
                align-items: center;
                width: 124px;
                padding: $gutterXXLarge;
                max-height: 30px;

                img {
                    max-width: 100%;
                    box-sizing: border-box;
                    max-height: 100%;
                }
            }

            .label-component {
                padding: $gutterXXLarge;
                padding-left: 0px;
            }

            .survey-name {
                display: flex;
                align-items: center;
                padding: 20px;
                flex-grow: 1;
            }

            .language-selector {
                display: flex;
                align-items: center;
                padding-right: $gutterXXLarge;

                .ms-Dropdown {
                    .ms-Dropdown-title {
                        font-size: 16px;
                        color: color(p-dark-grey-1);
                        background-color: transparent;
                        height: 56px;
                        border: none !important;
                        padding-right: 30px;
                    }

                    .ms-Dropdown-caretDownWrapper {
                        top: -1px;
                        margin-right: 0px;
                    }
                }
            }

            .info-wrapper {
                display: flex;
                align-items: center;
                padding: 20px;
            }
        }

        &.light-text {
            .survey-name {
                .label-component {
                    color: color(p-white);
                }
            }

            .info-wrapper {
                a {
                    span {
                        color: color(p-white);
                    }
                }
            }

            .language-selector {
                .ms-Dropdown-container {
                    .ms-Dropdown {
                        span {
                            color: color(p-white) !important;
                        }

                        .ms-Dropdown-caretDownWrapper {
                            i {
                                color: color(p-white);
                            }
                        }
                    }
                }
            }
        }
    }

    .main {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        padding: $gutterXXLarge;
        box-sizing: border-box;
    }

    .SurveyViewMainContent {
        width: 420px;
        max-width: 100vw;
        z-index: 1;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid color(p-border-color);
        padding: $gutterXXLarge;
        border-radius: $borderRadiusRound4;
        box-sizing: border-box;
        background-color: #FFF;

        .MainContentInner {
            animation: slide-in 0.3s forwards;
            -webkit-animation: slide-in 0.3s forwards;
            transform: translateX(100%);
            width: 100%;
        }

        .survey-header {
            display: flex;
            flex-direction: row;

            .survey-header-left {
                display: flex;
                flex-grow: 1;
                justify-content: center;
                flex-direction: column;

                .question-count {
                    margin-bottom: $gutterSmaller;
                }

                .thank-you-description {
                    margin-top: $gutterMedium;
                }
            }

            .survey-header-right {
                height: 106px;
                margin-left: $gutterXXLarge;

                img {
                    margin: -12px;
                }
            }
        }

        .SurveyView-options {
            margin-top: $gutterXXLarge;

            .SurveyView-option {
                display: block;
                padding: $gutterMedium;
                margin-top: $gutterMedium;
                transition: background-color $fastTransition;
                -moz-transition: background-color $fastTransition;
                -o-transition: background-color $fastTransition;
                transition: background-color $fastTransition;
                border-radius: $borderRadiusRound2;
                background-color: color(p-light-grey-1);

                &:first-child {
                    margin-top: 0px;
                }

                >div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    align-content: center;

                    .selector-outer {
                        border: 1px solid color(p-light-grey-6);
                        background-color: color(p-white);
                        height: 23px;
                        width: 23px;
                        border-radius: 23px;
                        margin-right: $gutterSmall;
                        display: flex;
                        align-items: center;
                        align-content: center;
                        justify-content: center;
                    }

                    .label-component {
                        position: relative;
                        max-width: calc(100% - 34px);
                    }
                }

                &:hover {
                    opacity: 0.75;
                }

                &.is-selected {
                    .label-component {
                        color: color(p-white) !important;
                    }

                    .selector-outer {
                        border-color: color(p-white) !important;
                        background-color: color(p-white) !important;

                        .selector-inner {
                            height: 12px;
                            width: 12px;
                            background-color: color(p-green-1);
                            border-radius: 12px;
                        }
                    }
                }
            }
        }

        .survey-comment {
            margin-top: $gutterMedium;
            display: flex;
            flex-direction: row;
            height: 50px;
            align-items: center;

            .survey-comment-left {
                border-radius: $borderRadiusSharper;
                background-color: color(p-light-grey-1);
                height: 32px;
                width: 32px;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;
                margin-right: $gutterTiny;

                .Visage2Icon {
                    margin-top: 5px;
                }
            }

            .survey-comment-right {
                flex-grow: 1;
                margin-top: 2px;

                .ms-TextField-fieldGroup {
                    border: none !important;
                    height: 32px !important;

                    &::after {
                        border: none !important;
                    }
                }

                input {
                    font-size: 14px;
                    padding-left: $gutterSmall;
                }
            }
        }
    }

    .anon-header {
        padding: $gutterSmall;
        text-align: center;
        z-index: 1;
        position: relative;
    }

    .send-button-wrapper {
        text-align: center;
    }

    .send-button {
        padding: $gutterSmall;
        text-align: center;
        margin-top: $gutterXXXLarge;
        box-sizing: border-box;
        border-radius: $borderRadiusRounder;
        animation: widthAnimation 300ms ease-in-out;
        animation-fill-mode: forwards;
        background-color: color(p-link-color);

        &:hover {
            opacity: 0.75;
        }
    }

    .survey-comment-wrapper {
        margin-top: $gutterXLarge;
        display: flex;
        flex-direction: row;
        gap: $gutterSmaller;

        .FormControl {
            margin-bottom: 0px !important;
        }

        .Visage2Icon {
            margin-top: 4px;
        }

        .ms-TextField {
            flex-grow: 1;

            &:not(.ms-TextField--multiline) {
                .ms-TextField-fieldGroup {
                    height: 24px !important;
                }
            }
        }

        .ms-TextField-fieldGroup {
            border: none !important;

            &::after {
                border: none !important;
            }

            .ms-TextField-field {
                padding: 0px;
            }

            input, textarea {
                border-radius: 0px;
            }

            textarea {
                line-height: 24px;
                padding-bottom: $gutterXXLarge;
            }
        }
    }

    .footer {
        padding: $gutterXXLarge;
        border-top: 1px solid color(p-border-color);
        width: 100vw;
        display: flex;
        justify-content: center;
        box-sizing: border-box;

        .footer-inner {
            width: 420px;
            max-width: 100vw;
            display: flex;
            flex-direction: row;
            gap: $gutterMedium;

            > * {
                flex-grow: 1;
                width: 50%;
            }

            .ms-Button--primary {
                &:not(.is-disabled) {
                    background-color: color(p-link-color);
                    border-color: color(p-link-color);
                }
            }
        }

        &.fake {
            opacity: 0;
        }
    }

    .survey-progress {
        margin: 0 auto;
        max-width: 550px;
        display: flex;
        background-color: color(p-white);
        align-content: center;
        align-items: center;
        justify-content: center;
        margin-top: $gutterLarge;
        margin-bottom: $gutterLarge;
        border-radius: $borderRadiusSharper;
        padding: $gutterSmall $gutterMedium;

        .left-button,
        .right-button {
            height: 32px;
            width: 32px;
            border-radius: 32px;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            transition: background-color $fastTransition;
            -moz-transition: background-color $fastTransition;
            -o-transition: background-color $fastTransition;
            transition: background-color $fastTransition;

            .Visage2Icon {
                display: block;
                margin-top: 4px;
            }

            &:hover {
                opacity: 0.75;
            }

            &.disabled {
                background-color: color(p-light-grey-3) !important;
                pointer-events: none;
                cursor: default;
                opacity: 1;
            }
        }

        .left-button {
            .Visage2Icon {
                margin-left: -2px;
            }
        }

        .right-button {
            .Visage2Icon {
                margin-right: -2px;
            }
        }

        >.meter {
            flex-grow: 1;
            background-color: color(p-light-grey-3);
            height: 16px;
            margin: $gutterSmall;
            border-radius: $borderRadius;

            .meter-inner {
                background: linear-gradient(90deg, rgba(75, 159, 71, 0.5) 0%, rgba(75, 159, 71, 1) 100%);
                height: 16px;
                border-radius: $borderRadius;
                transition: width 0.5s;
            }
        }
    }

    .vector {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .progress-meter {
        width: 100%;
        height: 16px;
        border-radius: 20px;
        background-color: color(p-light-grey-1);
        margin-bottom: $gutterXXLarge;

        .progress-meter-inner {
            height: 16px;
            border-radius: 20px;
            background-color: color(p-light-grey-6);
            transition: width 0.5s;

            &.complete {
                background: linear-gradient(90deg, rgba(75, 159, 71, 0.5) 0%, rgba(75, 159, 71, 1) 100%);
            }
        }
    }

    .enps {
        margin-top: $gutterXXLarge;

        .enps-labels {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: $gutterSmall
        }

        .rating {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .unstyled-button {
                width: 20px;
                height: 20px;
                border: 1px solid color(borderColor);
                border-radius: 20px;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: center;

                &:hover {
                    opacity: 0.75;
                }
            }
        }
    }
}

.confetti {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
}

.confetti-piece {
    position: absolute;
    width: 10px;
    height: 30px;
    background: #fbb5d4;
    top: 0;
    opacity: 0;
}

.confetti-piece:nth-child(1) {
    left: 7%;
    transform: rotate(-40deg);
    animation: confettiAnimation 1000ms infinite ease-out;
    animation-delay: 182ms;
    animation-duration: 5116ms;
}

.confetti-piece:nth-child(2) {
    left: 14%;
    transform: rotate(4deg);
    animation: confettiAnimation 1000ms infinite ease-out;
    animation-delay: 161ms;
    animation-duration: 4076ms;
}

.confetti-piece:nth-child(3) {
    left: 21%;
    transform: rotate(-51deg);
    animation: confettiAnimation 1000ms infinite ease-out;
    animation-delay: 481ms;
    animation-duration: 6103ms;
}

.confetti-piece:nth-child(4) {
    left: 28%;
    transform: rotate(61deg);
    animation: confettiAnimation 1000ms infinite ease-out;
    animation-delay: 334ms;
    animation-duration: 1708ms;
}

.confetti-piece:nth-child(5) {
    left: 35%;
    transform: rotate(-52deg);
    animation: confettiAnimation 1000ms infinite ease-out;
    animation-delay: 302ms;
    animation-duration: 1776ms;
}

.confetti-piece:nth-child(6) {
    left: 42%;
    transform: rotate(38deg);
    animation: confettiAnimation 1000ms infinite ease-out;
    animation-delay: 180ms;
    animation-duration: 6168ms;
}

.confetti-piece:nth-child(7) {
    left: 49%;
    transform: rotate(11deg);
    animation: confettiAnimation 1000ms infinite ease-out;
    animation-delay: 395ms;
    animation-duration: 4200ms;
}

.confetti-piece:nth-child(8) {
    left: 56%;
    transform: rotate(49deg);
    animation: confettiAnimation 1000ms infinite ease-out;
    animation-delay: 14ms;
    animation-duration: 1887ms;
}

.confetti-piece:nth-child(9) {
    left: 63%;
    transform: rotate(-72deg);
    animation: confettiAnimation 1000ms infinite ease-out;
    animation-delay: 149ms;
    animation-duration: 1805ms;
}

.confetti-piece:nth-child(10) {
    left: 70%;
    transform: rotate(10deg);
    animation: confettiAnimation 1000ms infinite ease-out;
    animation-delay: 351ms;
    animation-duration: 6059ms;
}

.confetti-piece:nth-child(11) {
    left: 77%;
    transform: rotate(4deg);
    animation: confettiAnimation 1000ms infinite ease-out;
    animation-delay: 307ms;
    animation-duration: 7132ms;
}

.confetti-piece:nth-child(12) {
    left: 84%;
    transform: rotate(42deg);
    animation: confettiAnimation 1000ms infinite ease-out;
    animation-delay: 464ms;
    animation-duration: 1776ms;
}

.confetti-piece:nth-child(13) {
    left: 91%;
    transform: rotate(-72deg);
    animation: confettiAnimation 1000ms infinite ease-out;
    animation-delay: 429ms;
    animation-duration: 1818ms;
}

.confetti-piece:nth-child(odd) {
    background: #22234a;
}

.confetti-piece:nth-child(even) {
    z-index: 1;
}

.confetti-piece:nth-child(4n) {
    width: 5px;
    height: 12px;
    animation-duration: 2000ms;
}

.confetti-piece:nth-child(3n) {
    width: 3px;
    height: 10px;
    animation-duration: 3500ms;
    animation-delay: 3000ms;
}

.confetti-piece:nth-child(4n-7) {
    background: #ffd300;
}

@keyframes confettiAnimation {
    from {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    to {
        transform: translateY(90vh);
    }
}

@keyframes widthAnimation {
    from {
        width: 170px;
    }

    to {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .confetti {
        display: none;
    }

    .top-right-info {
        display: none;
    }

    .anon-banner {
        .label-component {
            font-size: 12px;
        }
    }

    .main {
        .SurveyViewMainContent {
            border: 0 !important;
            padding: 0px;
            border-radius: 0;
        }
    }

    .footer {
        .footer-inner {
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 720px) {
    .main {
        .SurveyViewMainContent {
            width: 100%;
        }
    }
}

.ms-Fabric--isFocusHidden {
    .SurveyView {
        .ms-Dropdown-container {
            outline: 0 !important;
            border: none !important;
            border-width: 0px !important;
            border-image-width: 0px !important;

            .ms-Dropdown {
                outline: 0 !important;
                border: none !important;
                border-width: 0px !important;
                border-image-width: 0px !important;

                .ms-Dropdown-title {
                    outline: 0 !important;
                    border: none !important;
                    border-width: 0px !important;
                    border-image-width: 0px !important;
                }

                &::after {
                    display: none;
                }
            }
        }
    }
}

@keyframes slide-in {
    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    100% {
        -webkit-transform: translateX(0%);
    }
}