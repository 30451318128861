@import "src/style/variables.scss";

.SpintrPagination {
    text-align: center;
    margin-top: $gutterXXLarge;

    .SpintrPagination-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        column-gap: $gutterXSmall;

        > .Visage2Icon {
            margin-top: $gutterXSmall;
        }

        .number {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: center;
            justify-content: center;

            &.SpintrPagination-icon {
                font-size: 14px;
            }

            &.active {
                width: 40px;
                color: color(p-white);
            }

            &:hover {
                background-color: color("p-light-grey-1");
            }

            &.inactive {
                pointer-events: none;
                color: color("p-mid-grey-2");
            }

            > .label-component {
                line-height: 22px;
            }

            > i {
                font-size: 14px;
                margin-top: -1px;
            }
        }

        .SpintrPagination-icon {
            margin: 0 $gutterXSmall;
        }
    }
}
