@import "src/style/variables";

.FormSteps {
    display: flex;
    flex-direction: column;

    .FormSteps-header {
        display: flex;
        flex-direction: row;
        gap: $gutterSmaller;
        align-items: center;
        margin-bottom: $gutterXXLarge;

        .FormSteps-header-step {
            display: flex;
            flex-direction: row;
            gap: $gutterSmaller;
            align-items: center;
        }
    }

    .FormStep {
        display: none;
    }

    .FormSteps-footer {
        margin-top: $gutterXXXLarge;
    }

    &.FormSteps-active-step-0 {
        .FormStep-0 {
            display: block;
        }
    }

    &.FormSteps-active-step-1 {
        .FormStep-1 {
            display: block;
        }
    }

    &.FormSteps-active-step-2 {
        .FormStep-2 {
            display: block;
        }
    }
}