@import "src/style/variables.scss";

.AnimatedDots {
    span {
		display:inline-block;
		animation: wave 1.3s linear infinite;

		&:nth-child(2) {
			animation-delay: -1.1s;
		}

		&:nth-child(3) {
			animation-delay: -0.9s;
		}
	}
}

@keyframes wave {
	0%, 30%, 100% {
		transform: translateY(-1px);
	}

	15% {
		transform: translateY(-4px);
	}
}