@import "src/style/variables.scss";

.ActionMenuItem {
    display: flex;
    padding: $gutterXSmall $gutterSmall;
    text-decoration: none;
    text-align: left;
    vertical-align: bottom;

    &:hover {
        background-color: color(p-light-grey-7);
    }

    > .icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: $gutterXSmall;
    }

    > .text {
        width: 100%;
    }
}