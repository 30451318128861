@import "src/style/variables";

.StatusBall {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    position: relative;
    top: 2px;
    margin-right: $gutterSmaller;
    
    &.status-1 {
        background-color: color(p-green-1);
    }

    &.status-0 {
        background-color: color(p-red-1);
    }
}
