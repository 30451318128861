@import "src/style/variables.scss";
.FormBirthday{
    .FormControl{
        margin-right: $gutterSmall;
    }
    
}
.FormControl {
    margin-bottom: $gutterMedium !important;
    .ms-TextField-wrapper{
        .ms-TextField-fieldGroup{
            min-height: 40px; // also used in multiline!!
            border-color: color(p-mid-grey-1);
            border-radius: $borderRadiusRound;
        }
    }
    .control{
        .dateInput{
            border: 1px solid color("p-mid-grey-2");
            border-radius: $borderRadiusRound;
            padding: $gutterSmall $gutterSmall $gutterSmall 35px;
        }
        .SpintrDatePicker{
            //margin-bottom: $gutterSmall;
        }
    }

    .ms-Checkbox{
        margin: $gutterXSmall 0;
    }

    > .label {
        label {
            font-weight: 600;
            color: color(p-mid-grey-1);
            font-size: 12px;
        }
    }

    .ms-TagItem{
        background-color: color("p-light-grey-2");

        .ms-Persona-initials {
            background-color: color("p-light-grey-1");
        }

        .ms-Persona-primaryText{
            color: color("p-dark-grey-1");
        }
    }
}

.FormControl .spacingTop{
    margin-top: $gutterXSmall;
}

.ms-Checkbox-text, .ms-Label{
    font-family: $font;
}