@import "src/style/variables.scss";

.ProjectSelector {
    display: flex;

    > div {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}

.project-selector-top, .project-selector-meta {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    gap: $gutterSmall;
    align-items: center;
    align-content: center;
}

.project-selector-meta {
    justify-content: space-between;
    margin-top: 3px;

    span {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        gap: 3px;
    }

    .vector-selector-project-part {
        display: flex;
        align-items: center;
        align-content: center;

        .unstyled-button {
            display: flex;
            flex-direction: row;
            gap: 3px;
            align-items: center;
            align-content: center;
            justify-content: center;

            .Visage2Icon {
                svg {
                    display: block;
                }
            }
        }
    }
}

.ProjectSelectorMenu {
    min-width: 300px;

    .ms-ContextualMenu-link {
        height: auto;

        .ProjectSelectorProject {
            padding: $gutterSmaller;
        }
    }
}