@import "src/style/variables.scss";

.ProjectStatusSelector {
    display: flex;
    align-items: center;
    gap: $gutterSmall;

    .status {
        display: flex;
        gap: $gutterXSmall;
    }
}
