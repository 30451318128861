@import "src/style/variables";
@import "src/components/Label/Label.scss";

.forgot-password-view {
    .content {
        .form-container {
            .forgot-password-label {
                margin-top: 148px;
                margin-bottom: 8px;
            }

            .password-requirements-label {
                margin-top: 15px;
            }

            .password-error-label {
                margin-top: 15px;
            }

            .ms-TextField {
                margin-top: 24px;
            }
        }

        .next-button {
            width: 100%;
            height: 56px;
            margin-top: 30px;
            border-radius: 8px;

            .ms-Button-label {
                @extend .fs-body-1;
                @extend .fw-bold;
            }
        }

        .back-button {
            display: block;
            text-align: center;
            margin-top: 30px;
        }
    }
}
