@import "./../../style/variables.scss";

.app {
    background-color: color(p-white);
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;
    display: flex;

    .app-content {
        flex-grow: 1;
        max-width: calc(100vw - 255px);
    }
}

.form-buttons {
    margin-top: $gutterXXXLarge;
}

.ms-Dropdown {
    //border: 1px solid color(p-border-color) !important;
    height: 48px;

    span {
        line-height: 48px !important;
    }

    .ms-Dropdown-title {
        border: 1px solid color(p-light-grey-6);
        height: 48px;
        padding-left: $gutterMedium;
        padding-right: 36px; // caret
    }

    .ms-Dropdown-caretDownWrapper {
        margin-right: $gutterSmall;
    }

    &:focus {
        &::after {
            border: 0;
        };
    }

    &.is-disabled {
        &:hover {
            .ms-Dropdown-title {
                border: 0px;
            }
        }
    }
}

.ms-BasePicker .ms-BasePicker-text {
    border: 1px solid color(p-light-grey-6) !important;
}

.ms-TextField-fieldGroup {
    border: 1px solid color(p-light-grey-6);
    height: 48px;

    &::after {
        border: 1px solid color(p-light-grey-6);
    }

    input {
        border-radius: $borderRadiusRound;
        padding-left: $gutterMedium;
        padding-right: $gutterMedium;

        &::after {
            border-radius: $borderRadiusRound;
        }
    }
}

.ms-TextField--multiline {
    .ms-TextField-fieldGroup {
        height: auto;
        min-height: 48px !important;

        textarea {
            padding: 14px 16px;
        }
    }
}

.spinButton {
    >div {
        height: 48px;

        input {
            padding-left: $gutterLarge;
            padding-right: $gutterLarge;
        }

        &:after {
            border-radius: $borderRadiusRound;
            border: 1px solid color(p-light-grey-6);
            height: 48px;
        }
    }
}

.ms-Label {
    font-weight: 400;
    color: color(p-dark-grey-1);
    padding-bottom: 6px;
}

.FormControl {
    .ms-Label {
        font-weight: 400 !important;
        color: color(p-mid-grey-1);
        font-size: 12px;
    }
}

body {
    &:not(.ms-Fabric--isFocusVisible) {
        .ms-TextField-fieldGroup {
            border: 1px solid color(p-light-grey-6) !important;

            &::after {
                border: 0px;
            }
        }
    }
}

.ms-MessageBar {
    border-radius: $borderRadiusSharper !important;
    margin-bottom: $gutterLarge;

    .ms-MessageBar-innerText {
        margin-top: 2px;
    }

    .ms-MessageBar-dismissSingleLine {
        button {
            margin-top: 1px;
        }
    }
}

.ms-Pivot {
    .ms-Pivot-link {
        padding: 0px !important;
        margin-right: $gutterLarge;
        color: color(p-mid-grey-4);

        &::before {
            left: 0px;
            right: 0px;
        }

        &:hover {
            background-color: transparent !important;
            color: color(p-link-color);
        }

        &.is-selected {
            color: color(p-link-color);

            &::before {
                background-color: color(p-link-color);
            }
        }
    }
}

.ms-Checkbox-checkbox {
    margin-right: $gutterXSmall;
}

.ms-DatePicker {
    .ms-TextField-field {
        padding-left: $gutterMedium;
        height: 48px;
        line-height: 48px;
    }

    .ms-DatePicker-event--with-label {
        margin-top: 7px;
        margin-right: 7px;
    }
}

.ms-ComboBox-container {
    .ms-ComboBox {
        height: 48px;
        padding-left: $gutterMedium;
        padding-right: 38px;

        &:after {
            border-radius: $borderRadiusRound;
            border: 1px solid color(p-light-grey-6);
        }

        button {
            &:hover {
                background-color: #FFF;
            }
        }
    }
}

.FormControl .control .dateInput {
    border: 1px solid color(p-border-color) !important;
    height: 48px;
    box-sizing: border-box;
    padding-left: 50px !important;
    font-size: 12px;
    color: color(p-mid-grey-4);
    outline: none !important;
    min-width: 100% !important;
}

.react-datepicker__close-icon {
    top: 17px !important;
    padding-right: 17px !important;
}

.SpintrDatePicker {
    .fa-calendar {
        top: 17px;
        left: $gutterLarge;
        color: color(p-mid-grey-4);
    }
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    left: -100px !important;
}

.ms-Callout {
    .ms-Dropdown-items {
        button {
            border-radius: 0px;
        }
    }
}

.ms-Link:hover { text-decoration: none; }

.small-button {
    padding: $gutterTiny $gutterSmaller !important;

    .ms-Button-flexContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $gutterXSmall;

        .Visage2Icon {
            svg {
                display: block;
            }
        }
    }

    &.blue {
        background-color: color(p-blue-3);
        border: 1px solid color(p-blue-3);
        color: color(p-link-color);

        .Visage2Icon svg path {
            fill: color(p-link-color);
        }
    }

    &.red {
        background-color: color(p-pink-2);
        border: 1px solid color(p-pink-2);
        color: color(p-red-1);

        .Visage2Icon svg path {
            fill: color(p-red-1);
        }
    }

    &.green {
        background-color: color(p-green-3);
        border: 1px solid color(p-green-3);
        color: color(p-green-1);

        .Visage2Icon svg path {
            fill: color(p-green-1);
        }
    }
}

.boxed-content {
    display: flex;
    flex-direction: column;
    padding: $gutterXXLarge;
    gap: $gutterXXLarge;
    flex-grow: 1;
    box-sizing: border-box;

    .row {
        display: flex;
        flex-direction: row;
        gap: $gutterXXLarge;
        box-sizing: border-box;

        .column {
            border: 1px solid color(p-border-color);
            flex-grow: 1;
            padding: $gutterXXLarge;
            border-radius: $borderRadiusRound;
            box-sizing: border-box;
            width: calc(50% - (#{$gutterXXLarge}) / 2);
            flex-shrink: 0;

            &.chart-column {
                max-width: 600px;
                position: relative;
                padding-bottom: $gutterXXXLarge;
            }
        }

        &:first-child {
            .column {
                max-height: 340px;
                overflow-y: auto;
            }
        }
    }

    &.admin-home {
        .row {
            &:first-child {
                .column {
                    max-height: none;
                    overflow: hidden;
                }
            }
        }
    }
}

.marketplace-tooltip {
    padding: 6px 9px;

    .ms-Callout-beak,
    .ms-Callout-beakCurtain,
    .ms-Callout-main {
        background-color: #3F4692;

        >* {
            color: white;
        }
    }
}

.list-icon-button {
    position: relative;
    top: -2px;

    > * {
        display: inline-block;
        vertical-align: middle;
    }

    .Visage2Icon {
        display: inline-block;
        height: 20px;
        margin-left: $gutterXSmall;

        svg {
            display: block;
        }
    }
}

.content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $gutterXXLarge;

    &.vertical {
        flex-direction: column;
        align-items: flex-start;
        gap: $gutterXSmall;
    }

    .label-component {
        flex-grow: 1;
    }
}