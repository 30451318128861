@import "src/style/variables";

.HeaderMainSearch {
    position: relative;
    flex-grow: 1;
}

.HeaderMainSearch-callout {
    position: absolute;
    width: 440px;
    max-width: 440px;
    margin-top: $gutterXSmall;
    border-radius: $borderRadiusRound;
    border: 1px solid color(p-light-grey-13);
    background-color: #FFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    padding: $gutterSmall;
    box-sizing: border-box;

    .HeaderMainSearch-category {
        margin-bottom: $gutterXXLarge;

        > .label-component {
            margin-bottom: $gutterSmall;
        }

        .category-items {
            display: flex;
            flex-direction: column;
            gap: $gutterXLarge;

            .item {
                &:hover {
                    opacity: 0.75;
                }
            }
        }

        &:last-child {
            margin-bottom: 0px;
        }
    }

    .no-results {
        padding: $gutterXLarge 0;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
}
