@import "src/style/variables.scss";

.DetailsView {
    display: flex;
    flex-direction: column;

    .survey-comments-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        gap: $gutterSmaller;
    }

    .content-list-wrapper {
        border-radius: $borderRadiusRound;
        padding: $gutterXXLarge;
        border: 1px solid color(p-border-color);
        box-sizing: border-box;
    }

    .survey-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        gap: $gutterSmaller;

        .color-ball {
            width: 12px;
            height: 12px;
            border-radius: 12px;
        }
    }

    .chart-column {
        .chart-wrapper {
            position: relative;

            canvas {
                height: 200px !important;
            }

            .goal-label {
                position: absolute;
                padding: 2px 4px 2px 8px;
                background-color: color(p-link-color);
                border-bottom-left-radius: 4px;
                border-top-left-radius: 4px;
                display: flex;
                align-items: center;
                height: 22px;
                text-transform: uppercase;
                transform: translate(-100%, -50%);
                z-index: 1;
                box-shadow: 0.5px 0 0 color(p-link-color);
                box-sizing: border-box;

                &::after {
                    content: ' ';
                    border: 11px solid transparent;
                    border-left-width: 15px;
                    border-right-width: 15px;
                    border-left-color: color(p-link-color);
                    width: 0;
                    height: 0;
                    position: absolute;
                    right: -30.5px;
                }

                &::before {
                    content: ' ';
                    height: 98%;
                    width: 2px;
                    background-color: color(p-link-color);
                    position: absolute;
                    right: -1.5px;
                    top: 50%;
                    border-radius: 10px;
                    transform: translateY(-50%);
                }
            }
        }

        .actionmenu-component {
            position: absolute;
            padding: $gutterSmall;
            bottom: 0px;
            right: 0px;
            width: auto;
            height: auto;
            display: flex;

            .action-menu-button {
                display: flex;
                flex-direction: row;
                column-gap: $gutterXSmall;
                align-items: center;
            }
        }
    }

    .dropdown-container {
        width: 250px;

        .ms-Dropdown-container {
            > * {
                display: inline-block;
                vertical-align: middle;
                &.ms-Dropdown {
                    width: 100%;
                    max-width: 170px;
                }

                &.ms-Dropdown-label {
                    margin-right: $gutterMedium;
                }
            }
        }
    }
}

.Comments-list {
    .Comment-wrapper {
        margin: $gutterSmall $gutterMedium 0px;
        padding-bottom: $gutterSmall;
        border-bottom: 1px solid color(p-border-color);
    }

    .Comment {
        margin-top: $gutterSmall;
        display: flex;
        flex-direction: row;
        gap: $gutterSmall;

        .Comment-right {
            flex-grow: 1;
        }

        .top-row {
            display: flex;
            flex-direction: row;
            align-items: center;

            .label-component {
                &:first-child {
                    flex-grow: 1;
                }
            }
        }

        &:last-child {
            border-bottom: 0px;
        }
    }
}