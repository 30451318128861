@import "src/style/variables";
@import "src/components/Label/Label.scss";

.login-view {
    .content {
        .form-container {
            .sign-in-label {
                margin-top: 148px;
                margin-bottom: 30px;
            }

            .email-input {
                margin-top: 30px;
            }

            .password-input {
                margin-top: 24px;
            }

            .remember-forgot {
                margin-top: 16px;
                display: flex;
                justify-content: space-between;

                .ms-Toggle {
                    &.is-checked {
                        .ms-Toggle-background {
                            background-color: color(p-green-2);
                        }
                    }

                    label {
                        margin-left: 10px;
                        color: color(p-light-grey-8);
                        font-weight: normal;
                    }
                }

                .forgot-link {
                    color: color(p-red-6);
                    margin-top: 3px;
                }
            }

            .sign-in-button {
                width: 100%;
                height: 56px;
                margin-top: 32px;
                border-radius: 8px;

                .ms-Button-label {
                    @extend .fs-body-1;
                    @extend .fw-bold;
                }
            }

            .register-link {
                display: block;
                text-align: center;
                margin-top: 32px;
            }
        }
    }

    &.two-factor {
        .content {
            .form-container {
                .sign-in-label {
                    margin-bottom: 8px;
                }

                .sign-in-sublabel {
                    margin-bottom: 24px;
                }
            }
        }
    }
}
